import { Ref, ComponentType } from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'

export type Props = BoxProps & {
  'data-testid'?: string
  href?: string
  ref?: Ref<any>
}

const ExtendedBox: ComponentType<Props> = Box

export default ExtendedBox
