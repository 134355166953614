import { ParsedUrlQuery } from 'querystring'
import { format } from 'url'

export const call = (roomId: string) => ({
  href: '/call/[roomId]',
  as: `/call/${roomId}`,
})

export const messagesConversation = (conversationId: string) => ({
  href: '/messages/[conversationId]',
  as: `/messages/${conversationId}`,
})

export const callNotes = (roomId: string) => ({
  href: '/call/notes/[roomId]',
  as: `/call/notes/${roomId}`,
})

export const callRating = (roomId: string) => ({
  href: '/call/rating/[roomId]',
  as: `/call/rating/${roomId}`,
})

export const callSummary = (roomId: string) => ({
  href: '/call/summary/[roomId]',
  as: `/call/summary/${roomId}`,
})

export const packageDetail = (packageName: string, anchor = '') => ({
  href: `/courses/[packageName]${anchor && '#' + anchor}`,
  as: `/courses/${packageName}${anchor && '#' + anchor}`,
})

export const login = (query: ParsedUrlQuery = {}) => ({
  href: { pathname: '/auth', query: { kind: 'login', ...query } },
  as: { pathname: '/login', query },
})

export const signup = (query: ParsedUrlQuery = {}) => ({
  href: { pathname: '/auth', query: { kind: 'signup', ...query } },
  as: { pathname: '/signup', query },
})

export const coupon = (query: ParsedUrlQuery = {}) => ({
  pathname: '/coupon',
  query: { ...query },
})

export const couponSignin = (query: ParsedUrlQuery = {}) => ({
  pathname: '/coupon/sign-in',
  query: { ...query },
})

export const couponSignup = (query: ParsedUrlQuery = {}) => ({
  pathname: '/coupon/sign-up',
  query: { ...query },
})

export const couponRedeem = (query: ParsedUrlQuery = {}) => ({
  pathname: '/coupon/redeem',
  query: { ...query },
})

export const therapistDetail = (slug: string) => ({
  href: '/terapeuti/t/[slug]',
  as: `/terapeuti/t/${slug}`,
})

export const paymentCallback = (query?: ParsedUrlQuery) => linkWithQuery('/payment-callback', query)

export const linkWithQuery = (link, query: ParsedUrlQuery = {}) => {
  const cleanedQ = Object.entries(query).reduce((a, [k, v]) => (v === undefined ? a : { ...a, [k]: v }), {}) // remove undefined params
  return format({ pathname: link, query: cleanedQ })
}

export const blogCategory = (category: string) => ({
  href: `/blog/${category}`,
  as: `/blog/${category}`,
})
export const blogPost = (category: string, post: string) => ({
  href: `/blog/${category}/${post}`,
  as: `/blog/${category}/${post}`,
})

export const therapistSignUp = (partner: string, slug: string) => ({
  href: `/therapist-sign-up/${partner}/${slug}`,
  as: `/therapist-sign-up/${partner}/${slug}`,
})

export const externalLinks = {
  therapistHowTo: 'https://www.notion.so/terapio/Jak-na-Terap-io-9791ec9b04f6429bbd461cbedb6cacf0',
  therapistGuidelines:
    'https://www.notion.so/terapio/Obecn-doporu-en-pro-veden-terapie-on-line-c489b00183424c1c82eed9e16f7f4091',
  therapistFAQ: 'https://www.notion.so/terapio/FAQ-pro-terapeuty-708331831d5f4bd7818a3431ad0d8163',
  facebookTherapistGroup: 'https://www.facebook.com/groups/terap.ioproterapeuty/',
}

export const externalContentLinks = {
  coupons: { href: { href: '/content/poukazy',  }, external: true },
  joinUs: { href: { href: '/content/pro-terapeuty' }, external: true },
  podcast: { href: { href: '/content/podcast',  }, external: true },
  terms: { href: { href: '/content/podminky',  }, external: true },
  toBusiness: { href: { href: '/content/pro-firmy' }, external: true },
  team: { href: { href: '/content/tym' }, external: true },
  forMedia: { href: { href: '/content/pro-media' }, external: true },
  topics: { href: { href: '/content/temata' }, external: true },
  faq: { href: 'https://intercom.help/terapio/cs/', external: true }
}

export default {
  home: '/',
  tutorSearch: '/terapeuti',
  /** @deprecated */
  login: login(),
  /** @deprecated */
  signup: signup(),
  forgottenPassword: '/forgotten-password',
  changePassword: { as: '/change-password' },
  call: '/call',
  callRating: '/call/rating',
  callNotes: '/call/notes',
  callSummary: '/call/summary',
  dashboard: '/dashboard',
  pricing: '/pricing',
  topUp: '/credit',
  messages: '/messages',
  updateProfile: '/update-profile',
  notifications: '/notifications',
  howItWorks: '/how-it-works',
  onboarding: '/onboarding',
  proxyApi: '/proxy/api',
  phone: '/phone',
  privacy: '/privacy',
  faq: '/faq',
  payouts: '/payouts',
  billingInfo: '/billing-info',
  coupon: coupon,
  couponSignin: couponSignin,
  couponSignup: couponSignup,
  couponRedeem: couponRedeem,
  therapistDetail: therapistDetail,
  therapistCodex: '/dashboard/codex',
  assignTherapist: '/assignTherapist',
  signUpConfirmation: '/signUpConfirmation',
  blog: '/blog',
  blogCategory,
  blogPost,
  therapistSignUp,
}

export const apiLinks = {
  updateChecker: '/api/update-check',
  invoice: '/api/invoice',
}

export const cdnBaseUrl = 'https://cdn2.terap.io'

export const cdnContentType = {
  logos: '/img/logos/',
}
